import React from "react";
import Landing from "./landing";
import Information from "./information";
import NewsLetter from "./newsletter";
import Footer from "./footer";
import { FormattedMessage } from "react-intl";

export default () => {
  return (
    <div className="App">
      <Landing
        title={<FormattedMessage id="landing.title" />}
        subtitle={<FormattedMessage id="landing.subtitle" />}
      />
      <Information
        id="left-info"
        heading={<FormattedMessage id="information.left.heading" />}
        text={<FormattedMessage id="information.left.text" />}
      />
      <Information
        id="right-info"
        heading={<FormattedMessage id="information.right.heading" />}
        text={<FormattedMessage id="information.right.text" />}
        flag
      />
      <NewsLetter
        heading={<FormattedMessage id="newsletter.heading" />}
        text={<FormattedMessage id="newsletter.text" />}
      />
      <Footer />
    </div>
  );
};
