import React, { useState } from "react";
import { Formik, Form, useField } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import { gql, useMutation } from "@apollo/client";
import * as yup from "yup";
import Spinner from "react-spinners/CircleLoader";

yup.setLocale({
  mixed: {
    required: <FormattedMessage id="validation.required" />,
  },
  string: {
    email: <FormattedMessage id="validation.email" />,
  },
});

const ADD_NEWSLETTER = gql`
  mutation add_newsletter($email: String!) {
    insert_newsletter(email: $email) {
      id
    }
  }
`;

const Input = injectIntl(({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="field">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        {...field}
        {...props}
        placeholder={props.intl.formatMessage(props.placeholder)}
      />
      {meta.touched && meta.error ? (
        <div className="form-error">{meta.error}</div>
      ) : null}
    </div>
  );
});

export default () => {
  const [addNewsletter] = useMutation(ADD_NEWSLETTER);
  const [loading, setLoading] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  return (
    <div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={yup.object({
          email: yup.string().email().required(),
        })}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          addNewsletter({ variables: values })
            .then((v) => {
              setErrorMessage(null);
              setLoading(false);
              setSignedUp(true);
              resetForm();
            })
            .catch((error) => {
              setLoading(false);
              try {
                setErrorMessage(error.message.split(".")[0]);
              } catch {
                setErrorMessage(error.message);
              }
            });
        }}
      >
        <Form className="newsletter-form">
          {signedUp ? (
            <div className="signup-success">
              <FormattedMessage id="newsletter.success" />
            </div>
          ) : (
            <>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder={{ id: "newsletter.emailAddress" }}
              />
              <button className="newsletter-button-signup" type="submit">
                {loading ? (
                  <div className="spinner">
                    <Spinner size={32} color={"#3c6e71"} />
                  </div>
                ) : (
                  <FormattedMessage id="landing.registerButton" />
                )}
              </button>
            </>
          )}
        </Form>
      </Formik>
      {errorMessage && (
        <div className="error-graphql">
          {errorMessage === "Uniqueness violation" ? (
            <FormattedMessage id="newsletter.error.email.notUnique" />
          ) : (
            errorMessage
          )}
        </div>
      )}
    </div>
  );
};
