import React, { useState } from "react";
import Main from "./components/main";
import "./App.css";
import { IntlProvider } from "react-intl";
import messages from "./lang/index";
import LocaleContext from "./lang/localeContext";
import { defaultLocale } from "./settings";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { API_URL } from "./settings";

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
});

function App() {
  const languageStored = localStorage.getItem("locale");
  const [locale, setLocale] = useState(
    languageStored ? languageStored : defaultLocale
  );
  const value = { locale, setLocale };
  return (
    <ApolloProvider client={client}>
      <LocaleContext.Provider value={value}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <Main />
        </IntlProvider>
      </LocaleContext.Provider>
    </ApolloProvider>
  );
}

export default App;
