import React from "react";
import { FormattedMessage } from "react-intl";
import PhoneImage from "../images/phone.png";
import NewsletterForm from "./newsletterForm";

export default class extends React.Component {
  render() {
    return (
      <div id="signup" className="newsletter">
        <div className="newsletter-signup">
          <h2>{this.props.heading}</h2>
          <div>{this.props.text}</div>
          <NewsletterForm />
          <div>
            <FormattedMessage
              id="newsletter.terms"
              values={{
                a: (chunks) => <a href="#terms">{chunks}</a>,
              }}
            />
          </div>
        </div>
        <img id="phone-image" src={PhoneImage} alt="Phone" />
      </div>
    );
  }
}
