import React, { useContext } from "react";
import LocaleContext from "../lang/localeContext";
import FinlandFlag from "../images/lang/finland-flag-round-icon-32.png";
import USFlag from "../images/lang/united-states-of-america-flag-round-icon-32.png";

export default () => {
  const { setLocale } = useContext(LocaleContext);
  const changeLocale = (event) => {
    const newLocale = event.target.getAttribute("alt");
    setLocale(newLocale);
    localStorage.setItem("locale", newLocale);
  };
  return (
    <div className="language-switcher">
      <img className="flag" src={USFlag} alt="en-US" onClick={changeLocale} />
      <img
        className="flag"
        src={FinlandFlag}
        alt="fi-FI"
        onClick={changeLocale}
      />
    </div>
  );
};
