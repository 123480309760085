import React from "react";
import { ReactComponent as Flag } from "../images/flag.svg";

export default class extends React.Component {
  render() {
    return (
      <div className="information" id={this.props.id}>
        <h2>{this.props.heading}</h2>
        {this.props.flag && <Flag className="flag-icon" />}
        <div className="info-text">{this.props.text}</div>
      </div>
    );
  }
}
