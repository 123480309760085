import React from "react";
import { ReactComponent as Logo } from "../images/kataja_logo.svg";
import { FormattedMessage } from "react-intl";
import LanguageSwitcher from "./languageSwitcher";
import { Link } from "react-scroll";

export default (props) => {
  return (
    <div className="landing">
      <div className="topbar">
        <Logo className="logo" />
        <LanguageSwitcher />
        <Link to="signup" spy={true} smooth={true}>
          <button className="button-signup">
            <FormattedMessage
              id="landing.registerButton"
              description="Register button text"
              defaultMessage="Register"
            />
          </button>
        </Link>
      </div>
      <div className="landing-text">
        <h1 id="title">{props.title}</h1>
        <div id="subtitle">{props.subtitle}</div>
      </div>
    </div>
  );
};
